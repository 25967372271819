.App {
  text-align: center;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}

hr {
  width: 75% !important;
  margin: 10px auto;
}

/* .content {
  text-align: left;
  width: 100%;
  height: 100%;
  padding-left: 50px;
  padding-top: 10px;
  color: #003F87;
} */

.content {
  min-height: 100%;
  background-color: #FFF;
  /* padding: 25px 100px; */
}

.content a,
a:visited {
  text-decoration: none;
  color: #003F87;
}

.content button a {
  color: white;
}

.subcontent {
  text-align: left;
  width: 100%;
  height: 100%;
  color: #003F87;
  margin-left: 25px;
  margin-bottom: 15px;
  margin-top: 8px;
}

.subcontenttitle {
  text-align: left;
  font-size: 1.25em;
  font-weight: bold;
  color: #003F87;
  width: 100%;
  line-height: 100%;
  align-content: flex-start;
}

.subcontent a {
  text-decoration: underline;
  font-weight: 700;
}

.subcontent li {
  /* padding-left: 50px; */
  list-style-position: inside;
  list-style-type: none;
  font-weight: 500;
}

.subcontent li li {

  list-style-position: inside;
  list-style-type: "-";
  font-weight: normal;
}

.regular {
  font-weight: normal !important;
  padding-left: 25px;
}

.content-image {
  padding: 0;
  width: 100%;
}

.calendar {
  min-height: 600px;
  min-width: 400px;
}

#main-navigation {
  background-color: #003F87;
  font: normal normal normal 16px/1.4em din-next-w01-light,
    din-next-w02-light,
    din-next-w10-light;
  padding: 0 1rem;
}

#main-navigation a {
  text-decoration: none;
  display: flex;
  align-items: center;
  color: #FDC116;
  text-transform: uppercase;
}

#main-navigation a:hover {
  background-color: #035abd;
}

.resource-title {
  text-align: left;
  font-size: 1.25em;
  font-weight: bold;
  color: #003F87;
  width: 100%;
  line-height: 100%;
  align-content: flex-start;
  padding-left: 40px;
  padding-top: 10px;
}

.resource-section {
  text-align: left;
  font-size: 2em;
  font-weight: bold;
  color: #003F87;
  width: 100%;
  line-height: 100%;
  padding-left: 30px;
}

.section-spacer {
  padding-top: 15px;
  padding-bottom: 15px;
}

.scout-me-in {
  max-width: 500px;
  width: 100%;
}

#footer-prepared {
  width: 250px;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;

  >.col,
  >[class*="col-"] {
    padding-right: 0;
    padding-left: 0;
  }
}

.footer {

  background-color: #003F87;
  font: normal normal normal 16px/1.4em din-next-w01-light,
    din-next-w02-light,
    din-next-w10-light;
  /*added stuff*/
  color: #FDC116;
  gap: 2rem;
  margin: 0;
  padding: 5px;
}

.footerLinks {
  text-align: left;
}

.footerScouts {
  text-align: right;
}

.footer a,
.footer a:visited,
.footer a:hover {
  color: #FDC116;
  text-decoration: none;
  align-items: center;
}

label {
  color: #003F87;
  padding: 2px;
  margin: 5px;
}

input {
  color: #003F87;
  width: 100%;
  max-width: 400px;
  float: left;
  border: 2px solid #003F87;
  padding: 2px;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 5px;
}

input:focus {
  background-color: #9AB3D5;
}

textarea {
  color: #003F87;
  width: 100%;
  max-width: 400px;
  float: left;
  height: 100px;
  border: 2px solid #003F87;
  padding: 2px;
  margin: 5px;
  box-sizing: border-box;
  border-radius: 5px;
  resize: none;
}

textarea:focus {
  background-color: #9AB3D5;
}

.col-25 {
  float: right;
  text-align: right;
  width: 25%;
  margin-top: 6px;
}

.col-75 {
  float: left;
  width: 75%;
  max-width: 750px;
  margin-top: 6px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

.confirm {
  display: none;
  color: #003F87;
  font-size: 1.5em;
}

.confirmImage {
  width: 300px;
  margin-bottom: 30px;
  ;
}

.packevents {
  padding-top: 10px;
}

.packevents li {
  list-style-position: inside;
  list-style-type: none;
}

.rankHeader {
  width: 100%;
  text-align: center !important;
}

.rankImg {
  height: 200px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.rankImgLion {
  width: 275px;
  margin-bottom: 15px;
  margin-top: 15px;
}

/* Responsive layout - when the screen is less than 600px wide, make the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {

  .col-25,
  .col-75,
  input[type=submit] {
    width: 100%;
    margin-top: 0;
    text-align: left;
  }
}

.active {
  background-color: #9AB3D5 !important;
}

.adventureOn {
  max-width: 200px;
  height: 100%;
  object-fit: contain
}

.navContainer {
  height: 40px;
}

.responsiveCal {
  position: relative;
  padding-bottom: 75%;
  /* height: 0; */
  overflow: hidden;
}

.responsiveCal iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media all and (min-width: 768px) {
  .deskContent {
    display: block;
  }

  .phoneContent {
    display: none;
  }
}

@media all and (max-width: 767px) {
  .deskContent {
    display: none;
  }

  .phoneContent {
    display: block;
    min-height: 500px;
  }
}

div.dropdown-menu.show {
  background-color: #9AB3D5;
  color: #003F87;
}

a.dropdown-item {
  color: #003F87 !important;
}

.popcornTitleRight {
  font-size: 1.75em;
  text-align: right;
  font-weight: 800;
}

.popcornTitleRight span {
  color: #CE1126;
}

.popcornTitleLeft {
  font-size: 1.75em;
  text-align: left;
  font-weight: 800;
}

.popcornTitleLeft span {
  color: #FDC116;
}

.popcornHeader {
  font-weight: 800;
  color: #003F87;
}

.popcornBack {
  background-color: #D6CEBD;
}

.popcorn li {
  list-style-position: inside;
  list-style-image: url("images/popcornicon.png");
  font-weight: normal;
}

.popcornNoIcon li {
  list-style-position: inside;
  font-weight: normal;
  list-style-image: none;
}

.popcornSubDetail li{
  list-style-position: inside;
  margin-left: 20px;
  list-style: circle !important;
}

.popcorn ol li {
  list-style-position: inside;
  list-style-image: none;
  list-style-type: decimal;
  font-weight: normal;
}

.strong{
  font-weight: 700;
}

.emphasize {
  font-weight: 600;
}

.green{
  color:forestgreen;
  font-weight: 600;
}