html,
body {
  margin: 0;
  height: 100%;
  min-height: 100%;
}

body {
  margin: 0;
  /* background-color: #E9E9E4; */
  background-color: pink;
  font-size: 85%;
  line-height: 160%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  width: 100%;
  height:100%;
  padding: 0;
  min-height: 100%;
  color: #252525;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}